import React, { useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Layout from "../components/layout"
import SEO from "../components/seo"
import space from "../images/space.jpg"
import planet from "../images/planet.png"


const NotFoundPage = () => {

  useEffect(() => {

    //einrasten der herosections fuer desktop und tablet

    gsap.registerPlugin(ScrollTrigger)

    var aniTl = gsap.timeline()
      .addLabel("eins")
      .to("#planet", { x: "-=200", rotation: "-=10", transformOrigin: "center center", duration: 30, repeat: -1, yoyo: true })

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".headerWrap",
        pin: true,
        scrub: 1,
        start: "top top",
        end: "2000px",
        markers: false,
        pinSpacing: false
      }
    })
      .addLabel("eins")
      .to("#planet", { scale:"-=.2", y:"+=100",  rotation: "-=35", transformOrigin: "center center", duration: 5, yoyo: true })
      .addLabel("ende")

  }, [])

  return (

    <Layout>
      <SEO title="404: Not found" />
      <svg className="fullorhalfpage" viewBox="0 0 1440 950" preserveAspectRatio="xMidYMid slice" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient x1="50%" y1="20.7293198%" x2="50%" y2="100%" id="linearGradient-1lakhz8356-1">
            <stop stop-color="#FFFFFF" offset="0%"></stop>
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="404" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <image id="space" x="-53" y="-3" width="1545" height="1030" xlinkHref={space}></image>
            <image id="planet" x="416" y="33" width="1024" height="1024" xlinkHref={planet}></image>
            <a href="/">
              <g id="lost" transform="translate(443.500000, 304.000000)" font-family="IBMPlexSansCond-Bold, IBM Plex Sans Condensed" font-style="condensed" font-weight="bold">
                <text id="404" fill="url(#linearGradient-1lakhz8356-1)" fill-rule="nonzero" font-size="256">
                  <tspan x="69.5" y="262">404</tspan>
                </text>
                <text opacity="0.8" font-size="72" fill="#FFFFFF">
                  <tspan x="70" y="300">Lost in space.</tspan>
                </text>
                <text opacity="0.8" font-size="36" fill="#FFFFFF">
                  <tspan x="170" y="350">Alles auf Start</tspan>
                </text>
              </g>
            </a>
          </g>
        </g>
      </svg>
    </Layout>

  )

}

export default NotFoundPage